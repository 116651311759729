<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Vendor Category</label>
              <validation-provider
                #default="{ errors }"
                name="Vendor Category"
                rules="required"
              >
                <b-form-input
                  v-model="companyname"
                  placeholder="Enter Vendor Category"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Vendor Type</label>
              <validation-provider
                #default="{ errors }"
                name="Vender Type"
                rules="required"
              >
                <v-select
                  v-model="vendortype"
                  placeholder="None"
                  :options="vendertypeOptions"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-button
              class="mt-1"
              variant="primary"
              type="submit"
              @click.prevent="submitForm"
            >
              Submit
            </b-button>
            <b-button
              class="mt-1 ml-2"
              variant="primary"
              type="submit"
              @click="handleBack"
            >
              Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code'
import PinchScrollZoom from '@coddicat/vue-pinch-scroll-zoom'
import flatPickr from 'vue-flatpickr-component'
import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BTable
} from 'bootstrap-vue'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value
} from '@validations'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import axios from '@/components/axios'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    PinchScrollZoom,
    flatPickr,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BTable
  },
  data () {
    return {
      ifEdit: false,
      accessToken: localStorage.getItem('accessToken'),
      baseApi: process.env.VUE_APP_APIENDPOINT
    }
  },
  mounted () {
    const userData = JSON.parse(localStorage.getItem('UserRights'))
    const role = JSON.parse(localStorage.getItem('userData')).role
    if (role !== 'admin') {
      userData.map((item) => {
        if (item.modulename == 'Vendor Category') {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push('/master/accounting/vendorcategory')
            }
          } else if (item.add !== 1) {
            this.$router.push('/master/accounting/vendorcategory')
          }
        }
      })
    }
    this.ifEdit = !!this.$route.params.id
    this.ifEdit && getEditValue()
  },
  methods: {
    async getEditValue () {
      await axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.accessToken}`
        },
        url: `${this.baseApi}/getVendorcategoryById/${this.$route.params.id}`
      }).then((response) => {
        this.getEditItems(response.data.data)
      })
    },
    getEditItems (item) {
      item.map((item) => {})
    },
    submitForm (e) {
      const data = {}
      const ifEdit = !!this.$route.params.id
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          await axios({
            methos: `${ifEdit ? 'put' : 'post'}`,
            url: this.ifEdit
              ? `${baseApi}/vendorcategory/${this.$route.params.id}`
              : `${baseApi}/vendorcategory`,
            headers: {
              'content-type': 'application/jason',
              'Authorization': `Bearer ${accessToken}`
            },
            data: JSON.stringify(obj)
          })
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  variant: 'primary',
                  text: this.$route.params.id
                    ? 'Data Updated Successfully'
                    : 'Data Added Successfully'
                }
              })
              this.$router.push('/master/account/vendorcategory')
            })
            .catch((error) => console.log(error, 'error'))
        }
      })
    },
    handleBack () {
      this.$route.push('/master/account/vendorcategory')
    }
  }
}
</script>
